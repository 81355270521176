<div class="container clubs-page-tpl">
  <div class="row">
    <div class="top-bar col-12">
      <div class="breadcrumbs">
        <span class="breadcrumb-item">
          <a [routerLink]="['/clubs']">
            List of clubs
          </a>
        </span>
      </div>
      <div class="controls"></div>
    </div>
    <div class="main-content col-12">
      <div class="filters-wrapper">
        <div class="filters">
          <div class="server-side-filters">
            <div *ngIf="clubList">
              Total {{clubList.length}}
            </div>
            <button class="secondary-action-btn no-icon-btn" (click)="openImportCSVCoursesModal(importCSVCoursesModal)">
              <span>{{ 'Statistics.Import_csv_courses_btn' | translate }}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="datatable-wrapper">
        <div class="table-wrapper">
          <table>
            <thead>
              <tr class="top-header">
                <th class="club">
                  <span>Club</span>
                </th>
                <th class="id">
                  <span>ID</span>
                </th>
                <th class="create-date">
                  <span>Create Date</span>
                </th>
                <th class="update-date">
                  <span>Update Date</span>
                </th>
              </tr>
            </thead>
            <tbody>
            <tr class="hover_rows" *ngFor="let club of clubList | paginate: { itemsPerPage: 25, currentPage: p }">
              <td>
                {{club.name}}
              </td>
              <td>
                {{club.id}}
              </td>
              <td>
                {{club.created_at}}
              </td>
              <td>
                {{club.updated_at}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer" *ngIf="clubList">
          <div class="items-total-wrapper">
            <span>
              Total {{clubList.length}}
            </span>
          </div>
          <pagination-controls  class="table-pagination"
                                (pageChange)="p = $event"
                                maxSize="5"
                                directionLinks="false"
                                autoHide="true"
                                responsive="false">
          </pagination-controls>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #importCSVCoursesModal>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'Statistics.Import_csv_modal_title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="importCSVCoursesForm" name="importCSVCoursesForm" id="importCSVCoursesForm" [formGroup]="importCSVCoursesForm"  #f="ngForm" (ngSubmit)="importCSVCoursesForm.valid && csvCoursesFileSubmit($event)" [class.ng-submitted-error]="f.submitted && f.form.invalid" novalidate>
      <div class="form-group">
        <div class="input-wrapper file_input">
          <label for="csv_file">{{ 'Statistics.Select_file_coursess_label1' | translate }}</label>
          <input type="file" class="form-control" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="csv_file" id="csv_file"
                 formControlName="csv_file"/>
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && importCSVCoursesForm.controls.csv_file.errors">
          <p *ngIf="importCSVCoursesForm.controls.csv_file.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!importCSVCoursesForm.controls.csv_file.errors.required && importCSVCoursesForm.controls.csv_file.errors.invalidFormat">{{'Shared.Invalid_file_format' | translate:{file_format: '.xlsx'} }}</p>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button [disabled]="btnLoading" class="modal-submit-btn no-icon-btn" form="importCSVCoursesForm">
      <span>{{ 'Pros.Submit_file_modal_btn' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>
